import React, { useEffect, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useInView } from "react-intersection-observer"

// assets
import icThumbCircle from "assets/img/icon/ic-thumb-circle.png"

// hooks
import { useScrollAnim } from "components/hooks/hooks"

// mixins
import { onClickDataLayer } from "src/mixins/mixins"

const PTPCongratulation = ({ data, setInViewPlans }) => {
  const [isCopied, setIsCopied] = useState()
  const [trigger, anim] = useScrollAnim()
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: [0.25, 0.5, 0.75],
  })

  const handleCopy = (promoCode) => {
    onClickDataLayer("copyPromoCode", promoCode, "/prepaid-vs-postpaid")
    setIsCopied(true)
  }

  useEffect(() => {
    if (inView) {
      setInViewPlans(false)
    }

    return () => {
      setInViewPlans(true)
    }
  }, [inView, setInViewPlans])

  return (
    <section ref={trigger} className="sc-ptp-congrats">
      <div ref={ref} className="container">
        <div className="row">
          <div className="col-md-6 col-img">
            <div className={`ptp-congrats-img ${anim(1)}`}>
              <img src={icThumbCircle} alt="Thumb" className="img-fluid" />
            </div>
          </div>
          <div className={`col-md-6 d-column-center ${anim(2)}`}>
            <h2 className="mb-2">{data?.title}</h2>
            <p className="mb-4">{data?.description}</p>
            {data?.gift && (
              <div className="ptp-congrats-code">
                <span>{data?.giftSection?.title}</span>
                <div className="box box-dashed d-center-between mb-2">
                  <h4>{data?.giftSection?.code}</h4>
                  <CopyToClipboard
                    text={data?.giftSection?.code}
                    onCopy={() => handleCopy(data?.giftSection?.code)}
                  >
                    <h6>{isCopied ? `COPIED` : `COPY`}</h6>
                  </CopyToClipboard>
                </div>
                <small className="d-block text-muted">
                  {data?.giftSection?.description}
                </small>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default PTPCongratulation
