import React, { useEffect } from "react"
import { useMediaQuery } from "react-responsive"

// global
import TextSparkle from "components/global/partial/text-sparkle"

// partials
import Quotes from "./quotes"
import QuickProfile from "./quick-profile"

// hooks
import { useScrollAnim } from "components/hooks/hooks"

const MythAndFact = ({ data, key, setInViewPlans }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [trigger, anim] = useScrollAnim()
  const [factTrigger, factAnim] = useScrollAnim(0)
  const [mythTrigger, mythAnim, inMythView] = useScrollAnim(0.75)

  const { sectionMitos, sectionFakta, backgroundType } = data

  const highlightWord = (text) => {
    const regExp = /\[(.*?)\]/
    const highlightText = text.match(regExp)
    const splitText = text.split(regExp)

    return !!highlightText
      ? splitText?.map((item, i) => {
          // console.log(typeof highlightText[1])
          return item === highlightText[1] ? (
            <TextSparkle
              key={i}
              text={
                highlightText[1]?.includes("*")
                  ? highlightText[1]?.slice(1, -1)
                  : highlightText[1]
              }
              isSparkle={highlightText[1]?.includes("*")}
            />
          ) : (
            item
          )
        })
      : text
  }

  // useEffect(() => {
  //   setInViewPlans(inMythView)
  // }, [setInViewPlans, inMythView])

  return (
    <>
      {/* prettier-ignore */}
      <section
        key={key}
        ref={trigger}
        className={`sc-ptp-myth-fact py-main ${backgroundType?.chooseBackground === "image" ? `ptp-myth-fact-image` : `` } ${backgroundType?.backgroundImage?.theme}`}
        style={{ background: backgroundType?.chooseBackground === "image" ? isMobile ? `url(${backgroundType?.backgroundImage?.mobile?.mediaItemUrl}) no-repeat center center` : `url(${backgroundType?.backgroundImage?.desktop?.mediaItemUrl}) no-repeat center center` : "", backgroundSize: "cover", }}
      >
      <div ref={mythTrigger} className="container">
        <div className="row">
          {backgroundType?.chooseBackground === "image" ? (
            <div className="col-md-8 col-lg-6 ptp-myth">
              <span className={`ptp-label mb-md-down-2 ${anim(1)}`}>
                Katanya...
              </span>
              <h2 className={`${anim(2)}`}>
                {sectionMitos?.mitos}
              </h2>
            </div>
          ) : (
            <div className={`col-md-8 col-lg-6 ptp-myth ${anim(1)}`}>
              <Quotes
                text={sectionMitos?.mitos}
                isQuoteShowing={backgroundType?.chooseBackground !== "image"}
                anim={anim}
              />
              {sectionMitos?.hasProfile === "yes" && (
                <QuickProfile
                  data={sectionMitos?.profile}
                  inMythView={inMythView}
                  mythAnim={mythAnim}
                />
              )}
            </div>
          )}
        </div>
        <div ref={factTrigger} className="row" id="ptp-fact">
          <div className={`${ backgroundType?.chooseBackground === "image" ? `col-md-7` : `col-md-8 offset-md-4 col-lg-6 offset-lg-6` } ptp-fact ${factAnim(3)}`} >
            {sectionFakta?.hasBackgroundImage === "yes" ? (
              <>
                <div className="ptp-fact-bg">
                  <img
                    src={sectionFakta?.image?.mediaItemUrl}
                    alt="bg-phone"
                    className="img-fluid"
                  />
                  <div className="ptp-fact-bg-text">
                    <div className="ptp-fact-bg-text-wrapper">
                      <span className="ptp-label mb-2 mb-md-3">
                        Sebenarnya...
                      </span>
                      <h3 className="h4 mb-0">
                        {highlightWord(sectionFakta?.fakta)}
                      </h3>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <span className="ptp-label mb-md-down-2">Sebenarnya...</span>
                <h3>{highlightWord(sectionFakta?.fakta)}</h3>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default MythAndFact
