import React from "react"

const Quotes = ({ text, isQuoteShowing }) => {
  return (
    <h2 className={`ptp-quote ${isQuoteShowing ? `` : `ptp-quote-hide`}`}>
      <span className="ptp-quote-open">{text[0]}</span>
      {text?.slice(1, -1)}
      <span className="ptp-quote-close">{text[text.split("").length - 1]}</span>
    </h2>
  )
}

export default Quotes
