import React from "react"
import Lottie from "react-lottie"

// import grfxSparkle from "assets/img/common/grfx-sparkle-3.png"

// json
import animationSparkle from "assets/json/sparkle.json"

const TextSparkle = ({ text, isSparkle }) => {
  return (
    <>
      {/* <br className={`${isSparkle ? `d-none d-md-block` : `d-none`}`} /> */}
      <span
        className={`text-primary-gradient ${!isSparkle ? `no-sparkle` : ``}`}
      >
        {isSparkle && (
          <>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationSparkle,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid meet",
                },
              }}
              width={80}
              height={80}
            />
          </>
        )}{" "}
        {text}{" "}
      </span>
    </>
  )
}

TextSparkle.defaultProps = {
  isSparkle: true,
}

export default TextSparkle
