import React, { useEffect, useState, useCallback, memo } from "react"
// import CircularText from "./circular-text"

// components
import { Link } from "components/anti/link/link"

// mixins
import { onClickDataLayer } from "src/mixins/mixins"

const ViewPlans = () => {
  // const [offset, setOffset] = useState(0)

  // const handleScroll = useCallback(() => {
  //   window.onscroll = () => {
  //     setOffset(window.pageYOffset)
  //   }
  // }, [setOffset])

  const handleClick = () => {
    onClickDataLayer("packageButton", "Lihat Paket", "/prepaid-vs-postpaid")
  }

  // useEffect(() => {
  //   handleScroll()
  // }, [handleScroll])

  return (
    <>
      <Link
        className={`ptp-view-plans scroll-fadeInUp fadeInUp delayp2`}
        onClick={() => handleClick()}
        to="https://prioritas.xl.co.id/id/mobile/plans"
      >
        <strong>Lihat Paket</strong>
        {/* <div className="ptp-view-plans__circular-text">
          <CircularText
            text={"TERTARIK MENCOBA ?"}
            arc={280}
            radius={67}
            offset={offset}
            className="d-none d-md-inline"
          />
          <CircularText
            text={"TERTARIK MENCOBA ?"}
            arc={280}
            radius={55}
            offset={offset}
            className="d-md-none"
          />
        </div> */}
      </Link>
    </>
  )
}

export default memo(ViewPlans)
