import React from "react"

// anti components
import { Button } from "components/anti/buttons/buttons"
import { Image } from "components/anti/image/image"

const QuickProfile = ({ data, inMythView }) => {
  return (
    <div className="ptp-myth-profile">
      <img
        src={data?.photo?.mediaItemUrl}
        alt={`profile-${data?.photo?.mediaItemUrl?.split("").length}`}
        className="img-fluid"
      />
      <div className={`ptp-myth-profile__name`}>
        <span className="d-block">{data?.from}</span>
        <Button variant="link" className={`${inMythView ? `active` : ``}`}>
          {data?.buttonLabel}
        </Button>
      </div>
    </div>
  )
}

export default QuickProfile
