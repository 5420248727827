import React, { memo } from "react"

// partial
import MythAndFact from "./partial/cover-content-ptp"

const PTPMythFact = ({ data, setInViewPlans }) => {
  return (
    <>
      <div id="main-ptp-myth-fact">
        {data?.map((item, i) => (
          <MythAndFact key={i} data={item} setInViewPlans={setInViewPlans} />
        ))}
      </div>
    </>
  )
}

export default memo(PTPMythFact)
