import React from "react"
import { scroller } from "react-scroll"

// anti components
import { Cover } from "components/anti/cover/cover"
import { Button } from "components/anti/buttons/buttons"

// hooks
import { useScrollAnim } from "components/hooks/hooks"

// mixins
import { onClickDataLayer } from "src/mixins/mixins"

const PTPCover = ({ data }) => {
  const [trigger, anim] = useScrollAnim()

  const scrollTo = () => {
    scroller.scrollTo("main-ptp-myth-fact", {
      offset: 0,
      smooth: "easeInOutQuint",
      duration: 1500,
    })
    onClickDataLayer("factButton", "CEK FAKTANYA", "/prepaid-vs-postpaid")
  }

  return (
    <section ref={trigger} className="sc-ptp-cover">
      <Cover
        title={data?.title}
        text={data?.description}
        imgMd={data?.bannerImage?.desktop?.mediaItemUrl}
        img={data?.bannerImage?.mobile?.mediaItemUrl}
        // imgMd={
        //   data?.bannerImage?.desktop?.mediaItemUrlSharp?.childImageSharp?.fluid
        // }
        // img={
        //   data?.bannerImage?.mobile?.mediaItemUrlSharp?.childImageSharp?.fluid
        // }
        imgHeight="r-4-5 h-450px h-md-500px"
        titleClassName={`${anim(1)}`}
        textClassName={`${anim(2)}`}
        className="cover-ptp"
      >
        <div className={`btn-group mt-2 ${anim(3)}`}>
          <div className="overflow-hidden">
            <Button variant="link" onClick={scrollTo}>
              {data?.linkLabel}
            </Button>
          </div>
        </div>
      </Cover>
    </section>
  )
}

export default PTPCover
