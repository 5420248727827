import React, { useState, memo } from "react"
import { graphql, useStaticQuery } from "gatsby"

// anti components
import Layout from "components/Layout"
import Seo from "components/SEO"

// xl components
import PTPCover from "components/pages/ptp/ptp-cover"
import PTPMythFact from "components/pages/ptp/ptp-myth-fact"
import PTPCongratulation from "components/pages/ptp/ptp-congratulation"
import ViewPlans from "components/pages/ptp/partial/view-plans"

const PrePostPaid = ({ location }) => {
  const [inViewPlans, setInViewPlans] = useState(false)
  const page = useStaticQuery(Q_PRETOPOST).wordPress.page

  const { pagePrepostpaid: preToPostPage, seo: SEO, general } = page

  const { cover, congratulation, mitosDanFakta } = preToPostPage

  return (
    <>
      <Layout data={general}>
        <Seo YoastSEO={SEO} location={location} />

        <ViewPlans inViewPlans={inViewPlans} />

        <PTPCover data={cover} />
        <PTPMythFact data={mitosDanFakta} setInViewPlans={setInViewPlans} />
        <PTPCongratulation
          data={congratulation}
          setInViewPlans={setInViewPlans}
        />
      </Layout>
    </>
  )
}

const Q_PRETOPOST = graphql`
  query QueryPreToPost {
    wordPress {
      page(id: "prabayar-ke-pascabayar", idType: URI) {
        general {
          footer {
            description
          }
        }
        pagePrepostpaid {
          cover {
            title
            description
            linkLabel
            bannerImage {
              desktop {
                mediaItemUrl
              }
              mobile {
                mediaItemUrl
              }
            }
          }
          mitosDanFakta {
            backgroundType {
              chooseBackground
              backgroundImage {
                theme
                desktop {
                  mediaItemUrl
                }
                mobile {
                  mediaItemUrl
                }
              }
            }
            sectionMitos {
              mitos
              hasProfile
              profile {
                photo {
                  mediaItemUrl
                }
                from
                buttonLabel
              }
            }
            sectionFakta {
              fakta
              hasBackgroundImage
              image {
                mediaItemUrl
              }
            }
          }
          congratulation {
            title
            description
            gift
            giftSection {
              title
              code
              description
            }
          }
        }
        seo {
          canonical
          title
          metaDesc
          focuskw
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphAuthor
          opengraphDescription
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          opengraphUrl
          opengraphSiteName
          opengraphPublishedTime
          opengraphModifiedTime
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          breadcrumbs {
            url
            text
          }
          cornerstone
          readingTime
        }
      }
    }
  }
`

export default memo(PrePostPaid)
